








































































import { defineComponent, ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api'
import { debounce } from 'lodash'

import Loading from '@/elements/Loading.vue'
import SmsComposer from '@/views/Programs/Communications/vue/SmsComposer.vue'
import SmsPreviewer from '@/views/Programs/Communications/vue/SmsPreviewer.vue'
import MessageConfirmationModal from '@/views/Programs/Communications/vue/MessageConfirmationModal.vue'
import MessageFilterModal from '@/views/Programs/Communications/vue/Filters/MessageFilterModal.vue'
import CommunicationWarning from '@/views/Programs/Communications/vue/CommunicationWarning.vue'

import { SmsCompose, getEmptySmsCompose } from '@/views/Programs/Communications/model/SmsCompose'
import { getEmptyPreviewMessage } from '@/lib/support/models/Communication/PreviewMessage'
import { EmailMessageImpl, getEmptyEmail } from '@/views/Programs/Communications/ext/EmailMessageImpl'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { MessageFilters, convertToMessageFilters } from '@/views/Programs/Communications/model/MessageFilters'
import dayjs from 'dayjs'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'

import { isPhoneValid } from '@/lib/support/utilities/phone/phoneUtil'

import store from '@/store'
import router from '@/router/index'
import { mixin as clickaway } from 'vue-clickaway'
import DatePicker from '@/components/ModalDateTimePicker.vue'
export default defineComponent({
  name: 'Sms',
  props: {},
  components: {
    SmsComposer,
    SmsPreviewer,
    MessageConfirmationModal,
    MessageFilterModal,
    CommunicationWarning,
    DatePicker,
    Loading,
  },
  mixins: [clickaway],
  setup(props, ctx) {
    const messageID = computed(() => router.currentRoute.params.messageId)
    const logic = messageLogic()

    const compose = ref<SmsCompose>(getEmptySmsCompose())
    const smsComposer = ref<any | null>(null)

    const hasCredits = computed(() => {
      return (
        logic.providerInfo.value.smsMaxCredits < 0 ||
        logic.providerInfo.value.smsCredits < logic.providerInfo.value.smsMaxCredits
      )
    })

    const isAllowed = computed(() => {
      return logic.providerInfo.value.isSMSAllowed
    })

    const isAfterSeasonEndDate = computed(() => {
      if (logic.seasonEndDate.value) {
        return dayjs().diff(logic.seasonEndDate.value, 'month', true) < 12
      }
      return false
    })

    const apiPreview = computed(
      (): PreviewMessage => {
        //remove any invalid phone numbers and convert to string
        const toDelimited = compose.value.recipients.toDelimited.filter((e) => isPhoneValid(e)).join(', ')
        return {
          ...getEmptyPreviewMessage(),
          messageBody: compose.value.messageBody,
          ...logic.filters.value,
          selectedGroups: compose.value.recipients.selectedGroups,
          toDelimited,
          upwardLeagueID: logic.upwardLeagueID.value ?? '',
          accountNumber: logic.accountNumber.value,
          communicationType: 'sms',
        }
      }
    )

    const apiMessage = computed(
      (): EmailMessageImpl => {
        return {
          ...getEmptyEmail(),
          messageBody: compose.value.messageBody,
          to: logic.allRecipients.value,
          upwardLeagueID: logic.upwardLeagueID.value ?? '',
          accountNumber: logic.accountNumber.value,
          sendCommunicationOn: logic.sendCommunicationOn.value,
          minutesToAdd: logic.minutesToAdd.value,
          recipientCriteria: {
            ...logic.filters.value,
            upwardLeagueID: logic.upwardLeagueID.value ?? '',
            accountNumber: logic.accountNumber.value,
            selectedGroups: compose.value.recipients.selectedGroups,
            individualContactInfo: compose.value.recipients.toDelimited.filter((e) => isPhoneValid(e)),
          },
        }
      }
    )

    const loadRecipients = debounce(async () => {
      await logic.fetchRecipients(apiPreview.value)
    }, 500)

    async function sendSms() {
      const isValid = await smsComposer.value.triggerValidation()
      if (isValid) {
        logic.send(apiMessage.value, 'sendsms')
      }
    }
    async function scheduleSend() {
      logic.showScheduleSendModal.value = (await smsComposer.value.triggerValidation()) as boolean
    }
    async function loadSmsCompose() {
      const smsCompose = getEmptySmsCompose()
      if (messageID.value) {
        const message = await logic.retrieveMessage(parseInt(messageID.value), 'sms')
        smsCompose
        smsCompose.messageBody = message?.messageBody ?? ''
        smsCompose.recipients.selectedGroups = message?.recipientCriteria?.selectedGroups ?? []
        smsCompose.recipients.toDelimited = message?.recipientCriteria?.individualContactInfo ?? []
        logic.filters.value = convertToMessageFilters(message?.recipientCriteria)
      }
      compose.value = smsCompose
    }
    function loadTeams() {
      return new Promise<void>((resolve) => {
        ctx.root.$nextTick(async () => {
          try {
            logic.allTeams.value = await store.dispatch.teams.fetchTeams({
              upwId: logic.upwardLeagueID.value ?? '',
            })
          } finally {
            resolve()
          }
        })
      })
    }

    function filterChange(msgFilters: MessageFilters) {
      logic.filters.value = msgFilters
      loadRecipients()
    }

    watch(
      () => apiPreview.value.toDelimited,
      async () => {
        await loadRecipients()
      }
    )

    watch(
      () => apiPreview.value.selectedGroups,
      async (newValue, oldValue) => {
        if ([...newValue].sort().join() != [...oldValue].sort().join()) {
          await loadRecipients()
        }
      }
    )
    onMounted(async () => {
      await loadSmsCompose()
      await loadTeams()
    })
    onUnmounted(() => store.commit.communications.setPreviewMessage({ item: getEmptyPreviewMessage() }))

    return {
      compose,
      smsComposer,
      filterChange,
      sendSms,
      isAllowed,
      hasCredits,
      isAfterSeasonEndDate,
      scheduleSend,
      ...logic,
    }
  },
})
