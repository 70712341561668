import { render, staticRenderFns } from "./SmsMessageInput.vue?vue&type=template&id=154b0469&scoped=true&"
import script from "./SmsMessageInput.vue?vue&type=script&lang=ts&"
export * from "./SmsMessageInput.vue?vue&type=script&lang=ts&"
import style0 from "./SmsMessageInput.vue?vue&type=style&index=0&id=154b0469&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154b0469",
  null
  
)

export default component.exports