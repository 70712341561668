
































import { defineComponent, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import RecipientListPreview from '@/components/Communication/RecipientListPreview.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'
import ToClipboardBtn from '@/elements/ToClipboardBtn.vue'

import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import { SmsCompose } from '@/views/Programs/Communications/model/SmsCompose'

import { mergeAllRecipients } from '@/views/Programs/Communications/ext/allRecipients'

import store from '@/store'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
export default defineComponent({
  name: 'SmsPreviewer',
  props: {
    compose: { type: Object as PropType<SmsCompose>, require: true },
    providerInfo: { type: Object as PropType<EmailSMSAccount>, require: true },
    loadingRecipients: { type: Boolean, require: false, default: false },
  },
  data() {
    return {
      MessageChannelTypes,
    }
  },
  components: { RecipientListPreview, InputLabel, Loading, ToClipboardBtn },
  setup(props, { emit }) {
    const count = computed(() => recipients.value.length)

    const previewMessage = computed(() => store.getters.communications.messagePreview)

    const recipients = computed(() => {
      return mergeAllRecipients(cloneDeep(previewMessage.value))
    })

    function filterClick() {
      emit('filterClick')
    }
    const normalizePhone = (phone: string) => {
      if (!phone) return phone
      if (phone.includes('(')) return phone
      let cleaned = phone.charAt(0) === '+' ? phone.substring(1) : phone
      cleaned = cleaned.charAt(0) === '1' ? cleaned.substring(1) : cleaned
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)!
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return { count, recipients, filterClick, normalizePhone }
  },
})
