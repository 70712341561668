















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import MemoInput from '@/elements/MemoInput.vue'
import RecipientGroupInput from '@/views/Programs/Communications/vue/RecipientGroupInput.vue'

import { Recipients } from '@/views/Programs/Communications/model/Recipients'

export default defineComponent({
  name: 'EmailRecipientInput',
  props: {
    value: { type: Object as PropType<Recipients>, required: true },
  },
  components: { MemoInput, RecipientGroupInput },
  setup(props, { emit }) {
    const additionalNumbers = computed({
      get() {
        return props.value.toDelimited.join(', ')
      },
      set(val: string) {
        const x = cloneDeep(props.value)
        x.toDelimited = val
          .trim()
          .replace(/(^,)|(,$)/g, '') //remove trailing comma
          .split(' ') //remove extra spaces
          .join('')
          .split(',') //convert to array
        EmitData(x)
      },
    })

    const groups = computed({
      get() {
        return props.value.selectedGroups
      },
      set(val: string[]) {
        const x = cloneDeep(props.value)
        x.selectedGroups = val
        EmitData(x)
      },
    })

    function EmitData(data: Recipients) {
      emit('input', cloneDeep(data))
    }

    return { additionalNumbers, groups }
  },
})
