import { Recipients, getEmptyRecipients } from '@/views/Programs/Communications/model/Recipients'

export interface SmsCompose {
  recipients: Recipients
  messageBody: string
}

export function getEmptySmsCompose(): SmsCompose {
  return {
    recipients: getEmptyRecipients(),
    messageBody: '',
  }
}
