
















import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SmsRecipientInput from '@/views/Programs/Communications/vue/SmsRecipientInput.vue'
import SmsMessageInput from '@/views/Programs/Communications/vue/SmsMessageInput.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import InputLabel from '@/elements/InputLabel.vue'

import { Recipients } from '@/views/Programs/Communications/model/Recipients'
import { SmsCompose } from '@/views/Programs/Communications/model/SmsCompose'

import { mergeAllRecipients } from '@/views/Programs/Communications/ext/allRecipients'

import store from '@/store'

export default defineComponent({
  name: 'SmsComposer',
  props: {
    value: { type: Object as PropType<SmsCompose>, required: true },
  },
  components: {
    VeeValidateForm,
    SmsRecipientInput,
    InputlessStringValidator,
    InputLabel,
    SmsMessageInput,
  },
  setup(props, { emit }) {
    const form = ref<any | null>(null)
    function composeSmsCompose(): SmsCompose {
      return {
        recipients: recipients.value,
        messageBody: message.value,
      }
    }
    const message = computed({
      get() {
        return props.value.messageBody
      },
      set(v: string) {
        const data = composeSmsCompose()
        data.messageBody = cloneDeep(v)
        emit('input', data)
      },
    })

    const recipients = computed({
      get() {
        return props.value.recipients
      },
      set(v: Recipients) {
        const data = composeSmsCompose()
        data.recipients = cloneDeep(v)
        emit('input', data)
      },
    })

    const previewMessage = computed(() => store.getters.communications.messagePreview)

    const fetchedRecipients = computed(() => {
      return mergeAllRecipients(cloneDeep(previewMessage.value)).join()
    })

    async function triggerValidation() {
      return await form.value.reportValidity()
    }

    return {
      form,
      recipients,
      message,
      fetchedRecipients,
      triggerValidation,
    }
  },
})
